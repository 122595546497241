import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { AdminAuthService } from '../services/admin-auth.service';
import { navigationRoutes } from '../../../common/routes/navigation/navigation-routes';
import { UiUtilityService } from '../../../common/services/ui-utility/ui-utility.service';

@Injectable({
    providedIn: 'root',
})
export class PermissionGuardService {
    constructor(
        public adminAuthService: AdminAuthService,
        public router: Router,
        public uiUtilityService: UiUtilityService
    ) {}

    /* This method is use as auth guard for routes as anyone cant visit those routes without login */
    canActivate(route: ActivatedRouteSnapshot): boolean {
        // this will be passed from the route config on the data property
        const expectedPermission = route.data['expectedPermission'];
        const expectedScopePermission = route.data['expectedScopePermission'];

        // Permission guards for Projects routings
        /* here we check the project */
        if (this.adminAuthService.isAuthenticated() && this.adminAuthService.isProjectEnabled(expectedPermission)) {
            return true;
        }

        /* here we check the role */
        if (
            this.adminAuthService.isAuthenticated() &&
            this.adminAuthService.isModuleAssigned(expectedPermission, expectedScopePermission)
        ) {
            return true;
        } else if (!this.adminAuthService.isAuthenticated()) {
            this.uiUtilityService.handleError('Your session has timed out due to inactivity. Please log in again to continue.');
            this.router.navigate([navigationRoutes.navigateToAdminLogin]);
            return false;
        } else {
            this.router.navigate([navigationRoutes.navigateToUnAuthorized]);
            return false;
        }
    }
}
